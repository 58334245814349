import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import Intro from "../components/sustainability/Intro";
import ImpactList from "../components/impact/ImpactList";
import RegenerativePledge from "../components/common/regenerativePledge";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import Seo from "../components/seo";

export default function Impact({
  data: { sanityImpactPage, sanitySitesettings },
}) {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;
  let list;
  sanityImpactPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    } else if (element._type === "customList") {
      list = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero heroSection={hero} h2Size="text-3xl sm:text-[60px]" h2P="pb-7" />
      <Logo logo={sanitySitesettings?.logo} />
      {sanityImpactPage?._rawIntro && (
        <div className="tb-container px-[10px] mb-[25px] pt-5 show">
          <div className="w-[90%] mx-auto">
            <Intro data={sanityImpactPage._rawIntro} />
          </div>
        </div>
      )}

      {list && (
        <div className="tb-container">
          <ImpactList data={list} />
        </div>
      )}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <div>
          <RegenerativePledge
            data={regenerative}
            min_h="min-h-[72vh]"
            pb="pb-[80px]"
            px="px-[10px]"
          />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityImpactPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityCustomList {
          _type
          link
          linkTxt
          promiseList {
            svg
            _rawContent
          }
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }

        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }

      _rawIntro
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
